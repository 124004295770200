export default {
  env: 'production',
  urls: {
    api: 'https://connect-api.ralli.app',
    chatPlugin: 'https://plugin.ralli.app',
  },
  firebase: {
    apiKey: 'AIzaSyBNYZBTJick456sb9LEuyKZa3Bel8juqLM',
    authDomain: 'ralli-ab1e1.firebaseapp.com',
  },
  facebook: {
    appId: '636100780463594',
    permissions: 'pages_show_list,pages_messaging',
    apiUrl: 'https://graph.facebook.com/v6.0',
  },
  sentry: {
    dsn: 'https://00ccf3c2e0684f5eab37ce70262ba639@o340590.ingest.sentry.io/5608752',
  },
};
